import React from 'react'
import { createRoot } from 'react-dom/client';

import 'bootstrap'

import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'

function SiteHeader(props){
  return <h1 className="h1 text-center">{props.value}</h1>
}

function HomePageBody(props){
  return (
    <div className="container-md">
      <div>
        <SiteHeader value="justinMBurrous.com" />

        <div className="text-center">
          <p className="small-font-size text-muted">
            (one of those personal websites... in {new Date().getFullYear()}?)
          </p>

          <blockquote className="blockquote">
            <p>A software engineer in Seattle.</p>
          </blockquote>

          <a className="btn btn-secondary" href="https://github.com/justinmburrous">
            Github
          </a>
        </div>
      </div>
    </div>
  )
}

function BootstrapAlert(props){
  return (
    <div className={`text-center alert-dismissible fade show alert alert-${props.alertClass} ${props.hidden ? 'd-none' : ''}`} role="alert">
      {props.alertText}
    </div>
  )
}

class ContactForm extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      email_input: "",
      email_subject: "",
      email_body: "",
      alert_text: "",
      alert_class: "success",
      hide_alert: true,
      already_got_one: false
    }

    this.submitContact = this.submitContact.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
  }

  submitContact(event){
    const context = this;
    const apiEndpoint = "https://api." + window.location.host + "/contact";
    const contactData = {
      address: this.state.email_input,
      subject: this.state.email_subject,
      body: this.state.email_body
    }

    if(this.state.already_got_one){
      context.setState({
        alert_text: "He's already got one!",
        alert_class: "warning",
        hide_alert: false
      })
    } else {
      $.ajax({
        url: apiEndpoint,
        method: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(contactData),
        crossDomain: true
      }).done(function(data, textStatus, jqXHR){
        context.setState({
          alert_text: data.message,
          alert_class: "success",
          hide_alert: false,
          already_got_one: true,
          email_input: "",
          email_subject: "",
          email_body: ""
        })
      }).fail(function(jqXHR, textStatus, errorThrown){
        context.setState({
          alert_text: "Failed to submit contact.",
          alert_class: "danger",
          hide_alert: false,
          already_got_one: false
        })
      })
    }

    event.preventDefault()
  }

  changeHandler(event){
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  render(){
    return (
      <div>
        <BootstrapAlert
          alertText={this.state.alert_text}
          alertClass={this.state.alert_class}
          hidden={this.state.hide_alert}
        />

        <form onSubmit={this.submitContact}>
          <div className="mb-3">
            <label htmlFor="email_input" className="form-label">Email address</label>
            <input
              placeholder="Email..."
              type="email"
              className="form-control"
              id="email_input"
              aria-describedby="email_input_help"
              name="email_input"
              value={this.state.email_input}
              onChange={this.changeHandler}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email_subject" className="form-label">Subject</label>
            <input
              placeholder="Subject..."
              type="text"
              className="form-control"
              id="email_subject"
              aria-describedby="email_subject_help"
              name="email_subject"
              value={this.state.email_subject}
              onChange={this.changeHandler}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email_body" className="form-label">Body</label>
            <textarea
              placeholder="You know what to do here..."
              rows="5"
              type="text"
              className="form-control"
              id="email_body"
              aria-describedby="email_body_help"
              name="email_body"
              value={this.state.email_body}
              onChange={this.changeHandler}
            />
          </div>

          <button type="submit" className="btn btn-primary">Contact</button>
        </form>
      </div>
    )
  }
}

function ContactPage(props){
  return (
    <div className="container-md">
      <SiteHeader value="Contact me" />
      <ContactForm />
    </div>
  )
}

function Error404(props){
  return (
    <div className="text-center container-md">
      <SiteHeader />
      <h1>Whre am I?</h1>
      <p>(page not found)</p>
    </div>
  )
}

function RenderPage(props){
  const page = props.value;

  switch(page){
    case 'Home':
      return <HomePageBody />;
    case 'Contact':
      return <ContactPage />;
    default:
      return <Error404 />;
  }
}

function NavItem(props) {
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${props.active === true ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`}
        aria-current="page"
        href="/#"
        onClick={props.clickNavItem}
      >
        {props.value}
      </a>
    </li>
  );
}

class NavBar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      activeNav: 'Home'
    }
  }

  clickMe(navItem){
    let newState = {activeNav: navItem}
    this.setState(newState)
  }

  render() {
    const navItems = [
      {name: 'Home'},
      {name: 'Contact'},
      {name: 'Projects (soon)', disabled: true},
    ]

    const navList = navItems.map((i) =>
      <NavItem
        value={i.name}
        key={i.name}
        disabled={i['disabled'] || false}
        clickNavItem={() => this.clickMe(i.name)}
        active={this.state.activeNav === i.name}
      />
    )

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/#">justinMBurrous.com</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                {navList}
              </ul>
            </div>
          </div>
        </nav>

        <RenderPage
          value={this.state.activeNav}
        />
      </div>
    )
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<NavBar />);
